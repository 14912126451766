<template lang="pug">
.carrier-list
  template(v-if="availableCarriers.length")
    input(v-model="carrierLookup" placeholder="Search for a carrier...")
    ul.cl-carrier-list.cl-available-carriers
      li.cl-item(
        v-for="carrier in filteredCarriers()"
        @click="selectCarrier(carrier)"
        :key="carrier.id"
      )
        CarrierLogo(:carrier="carrier")
        .cl-name {{ carrier.name }}
          |
          |
          span.identifier ({{carrier.identifier}})

  .cl-selected-carriers(v-if="userControlledCarriers.length") Selected carriers:
    ul.cl-carrier-list
      li.cl-item(
        v-for="carrier in userControlledCarriers"
        :key="carrier.id"
      )
        CarrierLogo(:carrier="carrier")
        .cl-name {{ carrier.name }}
        .identifier ({{carrier.identifier}})
        button.cl-remove-carrier.small(v-on:click.stop @click.prevent="removeUserControlledCarriers(carrier.id)") x

  .cl-selected-carriers.cl-carrier-required(v-else) Please select at least one carrier

</template>

<script>
import { mapState } from 'vuex'
import store from '../store'
import Fuse from 'fuse.js'

export default {
  props: {
    userControlledCarriers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dropdownVisible: false,
      carrierLookup: '',
    }
  },

  computed: {
    ...mapState({
      carriers: state => state.carriers.carriers,
    }),
    availableCarriers,
    userCarrierIds,
  },

  watch: {},

  methods: {
    selectCarrier,
    filteredCarriers,
    removeUserControlledCarriers,
  },

  components: {
    CarrierLogo: require('@/components/CarrierLogo').default,
  },
}

/* Computed ---------------------------------------------------- */
function userCarrierIds() {
  return this.userControlledCarriers.map(carrier => {
    return carrier.id
  })
}

function availableCarriers() {
  return this.carriers.filter(carrier => {
    return !this.userCarrierIds.includes(carrier.id)
  })
}

/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function filteredCarriers() {
  if (this.carrierLookup === '') {
    return this.availableCarriers
  }

  const options = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['name', 'identifier'],
  }

  const fuse = new Fuse(this.availableCarriers, options)
  return fuse.search(this.carrierLookup).map(carrier => {
    return carrier.item
  })
}

function selectCarrier(carrier) {
  let newCarriers = [...this.userControlledCarriers]
  if (!newCarriers.find(c => c.id === carrier.id)) {
    newCarriers.push(carrier)
    this.$emit('carrierChange', newCarriers)
  }
  this.carrierLookup = ''
}

function removeUserControlledCarriers(carrierId) {
  let newCarriers = [...this.userControlledCarriers]
  newCarriers = newCarriers.filter(carrier => {
    return carrier.id !== carrierId
  })
  this.$emit('carrierChange', newCarriers)
  // this.$store.commit('carriers/SET_USER_CONTROLLED', this.userControlledCarriers)
}
</script>
