<template lang="pug">
#create-user(v-esc-close="cancel" :class="panelClass")
  icon.close-panel(data="@icon/x.svg" @click="cancel" width="16" height="16" title="Close")

  h1 Edit User

  form#edit-user-form(@submit.prevent="update")
    DynamicForm(
      :schema="form._fromConfig"
      :disabled="false"
      v-model="form"
      @status="saveStatus"
      ref="dynamicForm"
    )

    CarrierList(:userControlledCarriers="form.carrierList" @carrierChange="handleCarrierChange")

    .panel-footer
      button.cancel-btn(type="button" @click.prevent="cancel") Cancel
      button.action-btn(type="submit" @click.prevent="update" :disabled="form.carrierList.length == 0") Update
</template>

<script>
import dynamicFormMixin from '@/mixins/dynamicFormMixin'
import PortalUser from '@/models/portalUser.js'

export default {
  mixins: [dynamicFormMixin],
  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      // form: { ...new PortalUser(this.user, ['email', 'password', 'passwordConfirmation']) },
      panelClass: '',
    }
  },

  computed: {},

  watch: {},

  created() {
    this.form = { ...new PortalUser(this.user, ['email', 'password', 'passwordConfirmation']) }
  },

  mounted() {
    setTimeout(() => {
      this.panelClass = 'is-visible'
    }, 100)
  },

  methods: {
    handleCarrierChange(carriers) {
      this.form.carrierList = carriers
    },
    cancel,
    update,
  },

  components: {
    CarrierList: require('@/components/CarrierList').default,
  },
}

/* Computed ---------------------------------------------------- */

/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function cancel() {
  this.panelClass = ''
  setTimeout(() => {
    this.$panel.close('EditUser')
  }, 250)
}

async function update() {
  this.$refs.dynamicForm.validate()

  if (this.status.invalid) {
    return
  }

  const response = await this.$store.dispatch('portalUser/update', this.form)

  if (response.status == 422) {
    response.data?.forEach(errorMessage => {
      this.$notyf.error(errorMessage)
    })
  } else if (response.status != 200) {
    this.$notyf.error(response.statusText)
  } else {
    this.$notyf.success('User updated!')
    this.cancel()
  }
}
</script>
