const dynamicFormMixin = {
  data() {
    return {
      form: {},
      status: null,
    }
  },

  computed: {},

  watch: {},

  render: () => null,

  methods: {
    saveStatus,
    validateForm,
  },

  components: {
    DynamicForm: require('@/components/DynamicForm').default,
  },
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function saveStatus(status) {
  this.status = status
}

function validateForm() {
  this.$refs.dynamicForm.validate()

  return !this.status.invalid
}

export default dynamicFormMixin
